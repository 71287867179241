'use strict';

import './src/assets/scss/init.scss'
import React from "react";
import { MDXProvider } from "@mdx-js/react";
import Code from "./src/components/Code";

const components = {
  pre: (props) => {
    const metaDataObject = props.children.props?.metaData?.split(' ')?.reduce((obj, item) => {
        const [key, value] = item.split('=');
        obj[key] = value;
        return obj;
    }, {});

    return <Code {...props} {...metaDataObject} />
},
  wrapper: ({ children }) => <>{children}</>
};

export const wrapRootElement = ({ element }) => {
  return <MDXProvider components={components}>{element}</MDXProvider>;
};