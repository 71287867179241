import React, { useRef, useEffect } from 'react';
import { Link } from 'gatsby';
import styles from './Page.module.scss';

const Page = ({ children, pageContext }) => {
  const pageRef = useRef();

  useEffect(() => {
    pageRef.current.scrollIntoView();
  });

  const header = () => {
    return (
      <header className={`${styles['header']} pillar`}>
        <div className={styles['header__logo']}>
          <Link rel="home" to={'/'}>
            <span>d</span><span>m</span><span>a</span><span>l</span><span>a</span><span>m</span><span>i</span><span>n</span><span>o</span><span>s</span>
          </Link>
        </div>
        <div className={`${styles['vspace-5']} md:hidden`}></div>
        <nav className={`${styles['header__nav']}`}>
          <Link rel="about" activeClassName={styles['active_nav_link']} to={'/about'}>About</Link>
          <Link rel="blog" activeClassName={styles['active_nav_link']} to={'/blog'} partiallyActive={true}>Blog</Link>
          <Link rel="music" activeClassName={styles['active_nav_link']} to={'/music'}>Music</Link>
        </nav>
      </header>
    )
  }

  const layout = () => {
    if (!pageContext.skipHeader) {
      return (
        <div ref={pageRef} className={styles['page']}>
          {header()}
          <div className={`pillar`}>
            {children}
          </div>
        </div>
      )
    } else {
      return (
        <div ref={pageRef}>
          {children}
        </div>
      )
    }
  }

  return layout();
};

export default Page;